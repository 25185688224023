<template>
	<div class="governmentss">
		<div class="container">
			<!-- <div class="loading">
				<div class="loadbox">
					<img src="picture/loading.gif" />
					页面加载中...
				</div>
			</div> -->
			<div class="header">
				<h3>科汇云服务数据云平台</h3>
				<p>{{ nowDate }}{{ nowTime }}</p>
			</div>
			<div class="main">
				<div class="mainCon">
					<div class="box leftCon" style="width: 24%;">
						<!-- 左一 -->
						<div class="tabs" style="height: 400px;">
							<h3>科技成果占比</h3>
							<div class="echartsBox" id="echarts1"></div>
						</div>
						<!-- 左二 -->
						<div class="tabs two" style="height: 260px;">
							<h3 style="margin-bottom: 15px;">专家人才库</h3>
							<ul class="tabTit">
								<li>
									<span>姓名</span>
									<span>学历</span>
									<span>领域类别</span>
									<span>联系方式</span>
								</li>
							</ul>
							<vue-seamless-scroll :data="echarts2" :class-option="optionHover" class="echartsBox" style="height: 156px; overflow: hidden;">
								<ul class="item">
									<li v-for="item in echarts2">
										<span>{{ item.name }}</span>
										<span>{{ item.education }}</span>
										<span>{{ item.ptype }}</span>
										<span>{{ item.number }}</span>
									</li>
								</ul>
							</vue-seamless-scroll>
						</div>
						<!-- 左三 -->
						<div class="tabs" style="height: 260px;">
							<h3>专家领域分布</h3>
							<div class="echartsBox" id="echarts3"></div>
						</div>
					</div>
					<div class="box centerCon" style="width: 52%;">
						<!-- 中一 -->
						<div class="tabs" style="height: 168px; display: flex; align-items: flex-start; justify-content: space-between;">
							<div class="leftPart">
								<h2>
									新增企业
									<i>(单位：户)</i>
								</h2>
								<p v-html="totalNum"></p>
							</div>
							<div class="rightPart"><div class="echartsBox" id="echarts4"></div></div>
						</div>
						<!-- 中二 -->
						<div class="tabs two" style="height: 350px;">
							<h3>成果与技术需求</h3>
							<div class="echartsBox" id="echarts5"></div>
						</div>
						<!-- 中三 -->
						<div class="tabs three" style="height: 340px;">
							<h3>技术合同登记（单位：千万）</h3>
							<div class="echartsBox" id="echarts6"></div>
						</div>
					</div>
					<div class="box rightCon" style="width: 24%;">
						<!-- 右一 -->
						<div class="tabs two" style="height: 220px;">
							<h3>服务资源库</h3>
							<ul class="tabTit">
								<li>
									<span style="width: 28%;">公司名称</span>
									<span style="width: 20%;">服务类别</span>
									<span style="width: 38%;">已服务企业</span>
								</li>
							</ul>
							<vue-seamless-scroll :data="echarts7" :class-option="optionHover" class="echartsBox" style="height: 136px; overflow: hidden;">
								<ul class="item">
									<li v-for="item in echarts7">
										<span style="width: 28%;">{{ item.name }}</span>
										<span style="width: 20%;">{{ item.ptype }}</span>
										<span style="width: 38%;">{{ item.number }}</span>
									</li>
								</ul>
							</vue-seamless-scroll>
						</div>
						<!-- 右二 -->
						<div class="tabs two" style="height: 300px;">
							<h3>技术需求库</h3>
							<ul class="tabTit">
								<li>
									<span style="width: 40%;">技术需求名称</span>
									<span style="width: 30%;">所属类别</span>
									<span style="width: 30%;">发布金额</span>
								</li>
							</ul>
							<vue-seamless-scroll :data="echarts8" :class-option="optionHover" class="echartsBox" style="height: 186px; overflow: hidden;">
								<ul class="item">
									<li v-for="item in echarts8">
										<span style="width: 40%;">{{ item.name }}</span>
										<span style="width: 30%;">{{ item.ptype }}</span>
										<span style="width: 30%;">{{ item.number }}</span>
									</li>
								</ul>
							</vue-seamless-scroll>
						</div>
						<!-- 右三 -->
						<div class="tabs two" style="height: 320px;">
							<h3>入驻企业分布</h3>
							<div class="echartsBox" id="echarts9"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { kjlist, zonghe, zjlist, qylists, chju, contractlist, servicek, jushuk, qylist } from '../../../common/js/api';
export default {
	name: 'servicepriseCloud2',
	components: {},
	data() {
		return {
			totals: 0,
			nowDate: null, //存放年月日变量
			nowTime: null, //存放时分秒变量
			timer: '', //定义一个定时器的变量
			currentTime: new Date(), // 获取当前时间
			echarts2: [],
			totalNum: 0,
			echarts7: [],
			echarts8: []
		};
	},
	created() {
		// 获取时间
		this.timer = setInterval(this.getTime, 1000);
		// 科技成果占比
		this.kjlist();
		// 专家人才库
		this.zonghe();
		// 专家领域发布
		this.zjlist();
		// 新增企业
		this.qylists();
		// 成果与技术需求
		this.chju();
		// 技术合同登记
		this.contractlist();
		// 服务资源库
		this.servicek();
		// 技术需求库
		this.jushuk();
		// 入驻企业分布
		this.qylist();
	},
	computed: {
		optionHover() {
			return {
				step: 0.3, //速度，值越大，速度越快
				hoverStop: true, //鼠标悬停效果，false为关闭该效果
				// singleHeight: 26, //单行停顿
				// waitTime: 2500 //单行停顿的时间
				limitMoveNum: 1 // 开始无缝滚动的数据量
			};
		}
	},
	mounted() {},
	methods: {
		// 获取时间
		getTime() {
			const date = new Date();
			const year = date.getFullYear();
			const month = date.getMonth() + 1;
			const day = date.getDate();
			const hour = date.getHours();
			const minute = date.getMinutes();
			const second = date.getSeconds();
			const str = '';
			if (this.hour > 12) {
				this.hour -= 12;
				// this.str = ' PM';
				this.str = ' ';
			} else {
				// this.str = ' AM';
				this.str = ' ';
			}
			this.month = check(month);
			this.day = check(day);
			this.hour = check(hour);
			this.minute = check(minute);
			this.second = check(second);
			function check(i) {
				const num = i < 10 ? '0' + i : i;
				return num;
			}
			this.nowDate = year + '年' + this.month + '月' + this.day + '日';
			this.nowTime = this.hour + '时' + this.minute + '分' + this.second + '秒' + this.str;
		},
		// 科技成果占比 - data
		kjlist() {
			var data = {
				name: [],
				num: [],
				nums: []
			};
			kjlist()
				.then(res => {
					res.data.map(item => {
						data.name.push(item.name);
						data.num.push(Number(item.num));
						data.nums.push(100);
					});
					data = data;
					this.$nextTick(function() {
						this.echarts1(data);
					});
				})
				.catch(() => {
					data = [];
				});
		},
		// 科技成果占比 - 图表
		echarts1(data) {
			let echarts1 = this.$echarts.init(document.getElementById('echarts1'));
			echarts1.setOption({
				tooltip: {
					show: false
				},
				grid: {
					top: '0%',
					left: '0',
					right: '12%',
					bottom: '0%',
					containLabel: true
				},
				xAxis: {
					min: 0,
					max: 100,
					splitLine: {
						show: false
					},
					axisTick: {
						show: false
					},
					axisLine: {
						show: false
					},
					axisLabel: {
						show: false
					}
				},
				yAxis: {
					data: data.name.reverse(),
					axisTick: {
						show: false
					},
					axisLine: {
						show: false
					},
					axisLabel: {
						color: 'rgba(255,255,255,.6)',
						fontSize: 10
					}
				},
				series: [
					{
						type: 'bar',
						barGap: '-100%',
						label: {
							show: true,
							zlevel: 10000,
							position: 'right',
							padding: 5,
							color: '#49bcf7',
							fontSize: 12,
							formatter: '{c}%'
						},
						itemStyle: {
							color: '#49bcf7'
						},
						barWidth: '10',
						data: data.num.reverse(),
						z: 10
					},
					{
						type: 'bar',
						barGap: '-100%',
						itemStyle: {
							color: '#fff',
							opacity: 0.1
						},
						barWidth: '10',
						data: data.nums,
						z: 5
					}
				]
			});
			window.addEventListener('resize', function() {
				echarts1.resize();
			});
		},
		// 专家人才库
		zonghe() {
			zonghe()
				.then(res => {
					this.echarts2 = res.data;
				})
				.catch(() => {
					this.echarts2 = [];
				});
		},
		// 专家领域发布 - data
		zjlist() {
			var data = {
				name: [],
				value: []
			};
			zjlist()
				.then(res => {
					res.data.map(item => {
						data.name.push(item.name);
						data.value.push({
							value: Number(item.num),
							name: item.name
						});
					});
					data = data;
					this.$nextTick(function() {
						this.echarts3(data);
					});
				})
				.catch(() => {
					data = [];
				});
		},
		// 专家领域发布 - 图表
		echarts3(data) {
			let echarts3 = this.$echarts.init(document.getElementById('echarts3'));
			echarts3.setOption({
				tooltip: {
					trigger: 'item',
					formatter: '{b} : {c} ({d}%)',
					textStyle: {
						lineHeight: 50
					}
				},
				legend: {
					right: 0,
					top: 10,
					height: 160,
					itemWidth: 10,
					itemHeight: 10,
					itemGap: 10,
					textStyle: {
						color: 'rgba(255,255,255,.6)',
						fontSize: 12,
						lineHeight: 18
					},
					orient: 'vertical',
					data: data.name
				},
				calculable: true,
				series: [
					{
						type: 'pie',
						radius: ['20%', '60%'],
						center: ['35%', '50%'],
						roseType: 'radius',
						color: ['#62c98d', '#2f89cf', '#4cb9cf', '#50ceff', '#e1cc00', '#6bdd9b'],
						label: {
							normal: {
								show: true
							},
							emphasis: {
								show: true
							}
						},
						labelLine: {
							show: true,
							length: 10,
							length2: 15
						},
						data: data.value
					}
				]
			});
			window.addEventListener('resize', function() {
				echarts3.resize();
			});
		},
		// 新增企业 - data
		qylists() {
			this.totalNum = 0;
			qylists()
				.then(res => {
					res.data.map(item => {
						this.totalNum += Math.floor(Math.round(item.num * 100) / 100);
					});
					this.$nextTick(function() {
						this.echarts4(res.data, this.totalNum);
					});
				})
				.catch(() => {
					res.data = [];
				});
		},
		// 新增企业 - 图表
		echarts4(data, totalNum) {
			let echarts3 = this.$echarts.init(document.getElementById('echarts4'));
			echarts3.setOption({
				title: [
					{
						subtext: '电子信息',
						top: '0',
						left: '21%',
						textAlign: 'center',
						subtextStyle: {
							fontSize: 12,
							color: '#fff'
						}
					},
					{
						subtext: '生物医药',
						top: '0',
						left: '52%',
						textAlign: 'center',
						subtextStyle: {
							fontSize: 12,
							color: '#fff'
						}
					},
					{
						subtext: '装备制造',
						top: '0',
						left: '83%',
						textAlign: 'center',
						subtextStyle: {
							fontSize: 12,
							color: '#fff'
						}
					}
				],
				series: [
					{
						type: 'pie',
						radius: ['68%', '80%'],
						label: {
							normal: {
								position: 'center'
							}
						},
						left: 0,
						right: '55%',
						top: '50px',
						bottom: 0,
						data: [
							{
								value: data[0].num,
								label: {
									formatter: function(params) {
										return `{a|${data[0].num}}\n{b|占比${Math.round((data[0].num / totalNum) * 100)}%}`;
									},
									rich: {
										a: {
											fontSize: 14,
											color: '#fff',
											lineHeight: 16
										},
										b: {
											fontSize: 10,
											color: '#bbb',
											lineHeight: 12
										}
									}
								},
								itemStyle: {
									normal: {
										color: '#49bcf7'
									},
									emphasis: {
										color: '#49bcf7'
									}
								}
							},
							{
								value: data[0].num,
								label: {},
								itemStyle: {
									normal: {
										color: 'rgba(255,255,255,.2)'
									},
									emphasis: {
										color: 'rgba(255,255,255,.2)'
									}
								}
							}
						]
					},
					{
						type: 'pie',
						radius: ['68%', '80%'],
						label: {
							normal: {
								position: 'center'
							}
						},
						left: '34%',
						right: '26%',
						top: '50px',
						bottom: 0,
						data: [
							{
								value: data[1].num,
								label: {
									formatter: function(params) {
										return `{a|${data[1].num}}\n{b|占比${Math.round((data[1].num / totalNum) * 100)}%}`;
									},
									rich: {
										a: {
											fontSize: 14,
											color: '#fff',
											lineHeight: 16
										},
										b: {
											fontSize: 10,
											color: '#bbb',
											lineHeight: 12
										}
									}
								},
								itemStyle: {
									normal: {
										color: '#cdba00'
									},
									emphasis: {
										color: '#cdba00'
									}
								}
							},
							{
								value: data[1].num,
								label: {},
								itemStyle: {
									normal: {
										color: 'rgba(255,255,255,.2)'
									},
									emphasis: {
										color: 'rgba(255,255,255,.2)'
									}
								}
							}
						]
					},
					{
						type: 'pie',
						radius: ['68%', '80%'],
						label: {
							normal: {
								position: 'center'
							}
						},
						left: '68%',
						right: 0,
						top: '50px',
						bottom: 0,
						data: [
							{
								value: data[2].num,
								label: {
									formatter: function(params) {
										return `{a|${data[2].num}}\n{b|占比${Math.round((data[2].num / totalNum) * 100)}%}`;
									},
									rich: {
										a: {
											fontSize: 14,
											color: '#fff',
											lineHeight: 16
										},
										b: {
											fontSize: 10,
											color: '#bbb',
											lineHeight: 12
										}
									}
								},
								itemStyle: {
									normal: {
										color: '#62c98d'
									},
									emphasis: {
										color: '#62c98d'
									}
								}
							},
							{
								value: data[2].num,
								label: {},
								itemStyle: {
									normal: {
										color: 'rgba(255,255,255,.2)'
									},
									emphasis: {
										color: 'rgba(255,255,255,.2)'
									}
								}
							}
						]
					}
				]
			});
			window.addEventListener('resize', function() {
				echarts3.resize();
			});
		},
		// 成果与技术需求 - data
		chju() {
			var data = {
				data1: [],
				data2: [],
				data3: []
			};
			chju()
				.then(res => {
					res.data.map(item => {
						data.data1.push(Number(item.one));
						data.data2.push(Number(item.ones));
						data.data3.push(Number(Math.round((item.ones / item.one) * 100) / 100) * 100);
					});
					this.$nextTick(function() {
						this.echarts5(data);
					});
				})
				.catch(() => {});
		},
		// 成果与技术需求 - 图表
		echarts5(data) {
			let echarts3 = this.$echarts.init(document.getElementById('echarts5'));
			echarts3.setOption({
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						lineStyle: {
							color: '#57617B'
						}
					}
				},
				legend: {
					data: [
						{
							name: '科技成果'
						},
						{
							name: '技术需求'
						},
						{
							name: '完成率'
						}
					],
					top: '0%',
					textStyle: {
						color: 'rgba(255,255,255,0.9)'
					}
				},
				xAxis: [
					{
						type: 'category',
						data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
						axisLine: {
							lineStyle: {
								color: 'rgba(255,255,255,.1)'
							}
						},
						axisLabel: {
							textStyle: {
								color: 'rgba(255,255,255,.6)',
								fontSize: '14'
							}
						}
					}
				],
				yAxis: [
					{
						type: 'value',
						name: '金额',
						// min: 0,
						// max: 50,
						// interval: 10,
						axisLabel: {
							show: true
						},
						axisLine: {
							lineStyle: {
								color: 'rgba(255,255,255,.4)'
							}
						}
					},
					{
						type: 'value',
						name: '完成率',
						show: true,
						axisLabel: {
							show: true
						},
						axisLine: {
							lineStyle: {
								color: 'rgba(255,255,255,.4)'
							}
						},
						splitLine: {
							show: true,
							lineStyle: {
								color: '#001e94'
							}
						}
					}
				],
				grid: {
					top: '10%',
					right: '30',
					bottom: '30',
					left: '30'
				},
				series: [
					{
						name: '科技成果',
						type: 'bar',
						data: data.data1,
						barWidth: 'auto',
						itemStyle: {
							normal: {
								color: {
									type: 'linear',
									x: 0,
									y: 0,
									x2: 0,
									y2: 1,
									colorStops: [
										{
											offset: 0,
											color: '#609db8'
										},

										{
											offset: 1,
											color: '#609db8'
										}
									],
									globalCoord: false
								}
							}
						}
					},
					{
						name: '技术需求',
						type: 'bar',
						data: data.data2,
						barWidth: 'auto',
						itemStyle: {
							normal: {
								color: {
									type: 'linear',
									x: 0,
									y: 0,
									x2: 0,
									y2: 1,
									colorStops: [
										{
											offset: 0,
											color: '#66b8a7'
										},
										{
											offset: 1,
											color: '#66b8a7'
										}
									],
									globalCoord: false
								}
							}
						},
						barGap: '0'
					},
					{
						name: '完成率',
						type: 'line',
						yAxisIndex: 1,
						data: data.data3,
						lineStyle: {
							normal: {
								width: 2
							}
						},
						itemStyle: {
							normal: {
								color: '#cdba00'
							}
						},
						smooth: true
					}
				]
			});
			window.addEventListener('resize', function() {
				echarts3.resize();
			});
		},
		// 技术合同登记 - data
		contractlist() {
			var data = {
				data1: [],
				data2: []
			};
			contractlist()
				.then(res => {
					res.data.map(item => {
						data.data1.push(Number(item.one));
						data.data2.push(Number(item.ones));
					});
					this.$nextTick(function() {
						this.echarts6(data);
					});
				})
				.catch(() => {
					data = [];
				});
		},
		// 技术合同登记 - 图表
		echarts6(data) {
			let echarts3 = this.$echarts.init(document.getElementById('echarts6'));
			echarts3.setOption({
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						lineStyle: {
							color: '#57617B'
						}
					}
				},
				legend: {
					data: ['去年', '今年'],
					top: '0',
					textStyle: {
						color: '#fff'
					},
					itemGap: 20
				},
				grid: {
					left: '0',
					right: '20',
					top: '10',
					bottom: '20',
					containLabel: true
				},
				xAxis: [
					{
						type: 'category',
						boundaryGap: false,
						axisLabel: {
							show: true,
							textStyle: {
								color: 'rgba(255,255,255,.6)'
							}
						},
						axisLine: {
							lineStyle: {
								color: 'rgba(255,255,255,.1)'
							}
						},
						data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
					},
					{}
				],
				yAxis: [
					{
						axisLabel: {
							show: true,
							textStyle: {
								color: 'rgba(255,255,255,.6)'
							}
						},
						axisLine: {
							lineStyle: {
								color: 'rgba(255,255,255,.1)'
							}
						},
						splitLine: {
							lineStyle: {
								color: 'rgba(255,255,255,.1)'
							}
						}
					}
				],
				series: [
					{
						name: '去年',
						type: 'line',
						smooth: true,
						symbol: 'circle',
						symbolSize: 5,
						showSymbol: false,
						lineStyle: {
							normal: {
								width: 2
							}
						},
						areaStyle: {
							normal: {
								color: this.$echarts.graphic.LinearGradient(
									0,
									0,
									0,
									1,
									[
										{
											offset: 0,
											color: 'rgba(24, 163, 64, 0.3)'
										},
										{
											offset: 0.8,
											color: 'rgba(24, 163, 64, 0)'
										}
									],
									false
								),
								shadowColor: 'rgba(0, 0, 0, 0.1)',
								shadowBlur: 10
							}
						},
						itemStyle: {
							normal: {
								color: '#cdba00',
								borderColor: 'rgba(137,189,2,0.27)',
								borderWidth: 12
							}
						},
						data: data.data1
					},
					{
						name: '今年',
						type: 'line',
						smooth: true,
						symbol: 'circle',
						symbolSize: 5,
						showSymbol: false,
						lineStyle: {
							normal: {
								width: 2
							}
						},
						areaStyle: {
							normal: {
								color: this.$echarts.graphic.LinearGradient(
									0,
									0,
									0,
									1,
									[
										{
											offset: 0,
											color: 'rgba(39, 122,206, 0.3)'
										},
										{
											offset: 0.8,
											color: 'rgba(39, 122,206, 0)'
										}
									],
									false
								),
								shadowColor: 'rgba(0, 0, 0, 0.1)',
								shadowBlur: 10
							}
						},
						itemStyle: {
							normal: {
								color: '#277ace',
								borderColor: 'rgba(0,136,212,0.2)',
								borderWidth: 12
							}
						},
						data: data.data2
					}
				]
			});
			window.addEventListener('resize', function() {
				echarts3.resize();
			});
		},
		// 服务资源库
		servicek() {
			servicek()
				.then(res => {
					this.echarts7 = res.data;
					this.totals = 0;
					for (var i = 0; i < res.data.length; i++) {
						this.totals += Number(res.data[i].number);
					}
				})
				.catch(() => {
					this.echarts7 = [];
				});
		},
		// 技术需求库
		jushuk() {
			jushuk()
				.then(res => {
					this.echarts8 = res.data;
				})
				.catch(() => {
					this.echarts8 = [];
				});
		},
		// 入驻企业分布 - data
		qylist() {
			var data = {
				name: [],
				value: []
			};
			qylist()
				.then(res => {
					res.data.map(item => {
						data.name.push(item.name);
						data.value.push({
							value: Number(item.num),
							name: item.name
						});
					});
					data.value.push(
						{
							value: 0,
							name: '',
							label: {
								show: false
							},
							labelLine: {
								show: false
							}
						},
						{
							value: 0,
							name: '',
							label: {
								show: false
							},
							labelLine: {
								show: false
							}
						},
						{
							value: 0,
							name: '',
							label: {
								show: false
							},
							labelLine: {
								show: false
							}
						},
						{
							value: 0,
							name: '',
							label: {
								show: false
							},
							labelLine: {
								show: false
							}
						}
					);
					data = data;
					this.$nextTick(function() {
						this.echarts9(data);
					});
				})
				.catch(() => {
					data = [];
				});
		},
		// 入驻企业分布 - 图表
		echarts9(data) {
			let echarts3 = this.$echarts.init(document.getElementById('echarts9'));
			echarts3.setOption({
				tooltip: {
					trigger: 'item',
					formatter: '{b} : {c} ({d}%)'
				},
				legend: {
					data: data.name,
					icon: 'circle',
					textStyle: {
						color: 'rgba(255,255,255,.6)'
					}
				},
				calculable: true,
				series: [
					{
						color: ['#62c98d', '#2f89cf', '#4cb9cf', '#50ceff', '#e1cc00', '#6bdd9b'],
						type: 'pie',
						startAngle: 0,
						radius: [51, 100],
						center: ['50%', '45%'],
						roseType: 'area',
						avoidLabelOverlap: false,
						label: {
							normal: {
								show: true
							},
							emphasis: {
								show: true
							}
						},
						labelLine: {
							normal: {
								show: true,
								length2: 1
							},
							emphasis: {
								show: true
							}
						},
						data: [
							{
								value: 1,
								name: '电子信息'
							},
							{
								value: 4,
								name: '生物医药'
							},
							{
								value: 5,
								name: '装备制造'
							},
							{
								value: 6,
								name: '农产品加工'
							},
							{
								value: 9,
								name: '能源化工'
							},

							{
								value: 10,
								name: '新材料'
							},
							{
								value: 0,
								name: '',
								label: {
									show: false
								},
								labelLine: {
									show: false
								}
							},
							{
								value: 0,
								name: '',
								label: {
									show: false
								},
								labelLine: {
									show: false
								}
							},
							{
								value: 0,
								name: '',
								label: {
									show: false
								},
								labelLine: {
									show: false
								}
							},
							{
								value: 0,
								name: '',
								label: {
									show: false
								},
								labelLine: {
									show: false
								}
							}
						]
					}
				]
			});
			window.addEventListener('resize', function() {
				echarts3.resize();
			});
		}
	},
	beforeDestroy() {
		if (this.timer) {
			clearInterval(this.timer); // 在Vue实例销毁前，清除定时器
		}
	}
};
</script>
<style lang="less" scoped>
.governmentss {
	width: 730px;
	// max-width: 100%;
	// width: 100%;
	// height: 100%;
	margin: 0 auto;
	background: #000d4a url(../../../assets/img/zhengfuyun/bg.jpg) no-repeat;
	background-position: center top;
	background-size: 100% 100%;
	.container {
		width: 100%;
		// .loading {
		// 	width: 100%;
		// 	height: 100%;
		// 	position: fixed;
		// 	left: 0;
		// 	top: 0;
		// 	font-size: 16px;
		// 	text-align: center;
		// 	z-index: 100000000;
		// 	background-color: #1a1a1c;
		// 	.loadbox {
		// 		position: absolute;
		// 		width: 160px;
		// 		height: 150px;
		// 		color: rgba(255, 255, 255, 0.6);
		// 		left: 50%;
		// 		top: 50%;
		// 		margin: -100px 0 0 -75px;
		// 		.loadbox img {
		// 			display: block;
		// 			width: 40px;
		// 			margin: 10px auto;
		// 		}
		// 	}
		// }
		.header {
			width: 100%;
			height: 60px;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			background: #000d4a url(../../../assets/img/zhengfuyun/head_bg.png) no-repeat;
			background-position: center top;
			background-size: 100% 100%;
			h3 {
				width: 40%;
				font-size: 24px;
				color: #fff;
				line-height: 50px;
				font-weight: bold;
				text-align: center;
			}
			p {
				margin-right: 30px;
				width: 30%;
				font-size: 14px;
				color: rgba(255, 255, 255, 0.9);
				line-height: 40px;
				text-align: right;
			}
		}
		.main {
			padding: 8px 15px 0;
			.mainCon {
				margin: 0 -0.4rem;
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				box-sizing: border-box;
				.box {
					padding: 0 0.2rem;
					.tabs {
						padding: 10px;
						margin-bottom: 10px;
						background-color: rgba(0, 0, 0, 0.2);
						h3 {
							height: 24px;
							padding-left: 10px;
							margin-bottom: 12px;
							font-size: 12px;
							color: #fff;
							line-height: 24px;
							position: relative;
							&:before {
								content: '';
								width: 3px;
								height: 15px;
								position: absolute;
								top: 5px;
								left: 0;
								border-radius: 15px;
								background-color: #49bcf7;
							}
						}
						.echartsBox {
							height: calc(100% - 30px);
						}
						&.two {
							.tabTit {
								padding: 0 0 6px 0;
								margin-bottom: 6px;
								border-bottom: 1px solid rgba(255, 255, 255, 0.2);
							}
							ul {
								width: 100%;
								li {
									width: 100%;
									display: flex;
									justify-content: space-between;
									span {
										display: inline-block;
										font-size: 14px;
										color: rgba(255, 255, 255, 0.6);
										text-overflow: ellipsis;
										white-space: nowrap;
										overflow: hidden;
										&:nth-child(1),
										&:nth-child(2) {
											width: 20%;
										}
										&:nth-child(3),
										&:nth-child(4) {
											width: 30%;
										}
									}
								}
							}
							.echartsBox {
								span {
									line-height: 30px;
								}
							}
						}
						.leftPart {
							width: 36%;
							h2 {
								padding-top: 8px;
								font-size: 16px;
								color: #fff;
								i {
									font-size: 12px;
									color: #fff;
								}
							}
							p {
								padding: 10px 0;
								margin: 15px 0;
								font-family: arial;
								font-size: 50px;
								color: #fef000;
								font-weight: bold;
								letter-spacing: 2px;
								border-top: 1px solid rgba(255, 255, 255, 0.1);
								border-bottom: 1px solid rgba(255, 255, 255, 0.1);
							}
						}
						.rightPart {
							width: 64%;
							height: 100%;
						}
					}
				}
			}
		}
	}
}
.nav-bar{
		display: none !important;
	}
</style>
